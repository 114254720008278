import { color } from '@renderbus/common/theme'
import React from 'react'
import {
  CellContainer,
  CellTitle,
  MobileContainer,
  PriceMobileBody,
  PriceMobileHeader,
  RowContainer,
  StudentText,
} from './atom'
import EducationIcon from './images/education.png'

export default class StudentPriceTableRow extends React.PureComponent {
  render() {
    return (
      <>
        <RowContainer>
          <CellContainer width='280px'>
            <div>
              <img src={EducationIcon} alt='教育优惠' />
              <CellTitle style={{ color: color.primary }}>教育优惠</CellTitle>
            </div>
          </CellContainer>
          <CellContainer>
            <StudentText>认证即享5折渲染，约¥0.125/核时</StudentText>
          </CellContainer>
        </RowContainer>
        <MobileContainer>
          <PriceMobileHeader>
            <img src={EducationIcon} alt='教育优惠' />
            <span>教育优惠</span>
          </PriceMobileHeader>
          <PriceMobileBody>
            <StudentText>认证即享5折渲染，约¥0.125/核时</StudentText>
          </PriceMobileBody>
        </MobileContainer>
      </>
    )
  }
}
