import React from 'react'
import PriceTableRow from './price-table-row'
import { CPUPriceList, PriceRank, rankTitleMap } from '../../pricing/price-card/price-list'
import { StudentPriceTableContainer } from './atom'
import StudentPriceTableRow from './student-price-table-row'

const StudentPriceTitleList = [PriceRank.Diamond, PriceRank.Gold, PriceRank.Ordinary].map(rank =>
  rankTitleMap.get(rank),
)
const StudentPriceList = CPUPriceList.filter(info => StudentPriceTitleList.includes(info.title))

export default class StudentPriceTable extends React.PureComponent {
  render() {
    return (
      <StudentPriceTableContainer>
        {StudentPriceList.map(price => (
          <PriceTableRow price={price} key={price.title} />
        ))}
        <StudentPriceTableRow />
      </StudentPriceTableContainer>
    )
  }
}
