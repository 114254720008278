import { Flex } from '@renderbus/common/components'
import React from 'react'
import {
  CellContainer,
  CellTips,
  CellTitle,
  MobileContainer,
  PriceMobileBody,
  PriceMobileHeader,
  RowContainer,
} from './atom'

export default class PriceTableRow extends React.PureComponent {
  render() {
    const { icon, title, trigger, price, corePrice, coreNum } = this.props.price
    return (
      <>
        <RowContainer>
          <CellContainer width='280px'>
            <div>
              <img src={icon} alt={title} />
              <CellTitle>{title}</CellTitle>
            </div>
          </CellContainer>
          <CellContainer width='263px'>
            <Flex
              style={{ height: '48px', width: '100%', paddingLeft: '30px' }}
              direction='column'
              valign='space-between'
              halign='flex-start'
            >
              <CellTips>充值金额</CellTips>
              <p>{trigger}</p>
            </Flex>
          </CellContainer>
          {corePrice && (
            <CellContainer width='240px'>
              <Flex
                style={{ height: '48px', width: '100%', paddingLeft: '30px' }}
                direction='column'
                valign='space-between'
                halign='flex-start'
              >
                <CellTips>单核</CellTips>
                <p>{corePrice}</p>
              </Flex>
            </CellContainer>
          )}
          {coreNum && (
            <CellContainer width='230px'>
              <Flex
                direction='column'
                style={{ height: '48px', width: '100%', paddingLeft: '30px' }}
                valign='space-between'
                halign='flex-start'
              >
                {coreNum.map((card, index) => (
                  <p key={index}>{card}</p>
                ))}
              </Flex>
            </CellContainer>
          )}
          <CellContainer>
            <Flex
              direction='column'
              style={{ height: '48px', width: '100%', paddingLeft: '30px' }}
              valign='space-between'
              halign='flex-start'
            >
              {price.map((card, index) => (
                <p key={index}>{card}</p>
              ))}
            </Flex>
          </CellContainer>
        </RowContainer>
        <MobileContainer>
          <PriceMobileHeader>
            <img src={icon} alt={title} />
            <span>{title}</span>
          </PriceMobileHeader>
          <PriceMobileBody>
            <span>{trigger}</span>
            <Flex halign='space-between'>
              <span>单核</span>
              <span>{corePrice}</span>
            </Flex>
            {coreNum.map((num, index) => (
              <Flex halign='space-between' key={index}>
                <span>{num}</span>
                <span>{price[index]}</span>
              </Flex>
            ))}
          </PriceMobileBody>
        </MobileContainer>
      </>
    )
  }
}
