import { color, hideSMDown, Media, spacing, transition, typography } from '@renderbus/common/theme'
import styled from 'styled-components'

export const StudentPriceTableContainer = styled.div`
  width: 1220px;
  max-width: 100%;
  margin: auto;
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  border-color: #505050;
  background-color: #333;
  transition: ${transition('background-color')};
  color: white;
  font-weight: 500;
  :not(:first-of-type) {
    border-top: none;
  }
  p {
    margin: 0;
    line-height: 1;
  }
  ${hideSMDown};
`

export const CellContainer = styled.div`
  width: ${p => p.width || '100%'};
  flex-shrink: ${p => (p.width ? 0 : 1)};
  margin: ${spacing.base} 0;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  :not(:last-of-type) {
    border-right: 1px solid #505050;
  }
`

export const CellTitle = styled.h3`
  margin: 0 0 0 28px;
  display: inline-block;
  font-size: ${typography.h3};
  vertical-align: top;
`

export const CellTips = styled.p`
  font-size: ${typography.textSmall};
  color: #999;
  font-weight: 400;
`

export const PriceMobileHeader = styled.div`
  padding: 0 ${spacing.large};
  height: 55px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #444;
  font-size: ${typography.h4};
  font-weight: 500;
  color: white;
  img {
    width: 23px;
    margin-right: 10px;
  }
`

export const PriceMobileBody = styled.div`
  margin-bottom: 15px;
  padding: ${spacing.small} ${spacing.large} 16px ${spacing.base};
  background-color: transparent;
  border: 1px solid #484848;
  border-top: none;
  line-height: 30px;
  font-size: ${typography.text};
  font-weight: 500;
  color: white;
`

export const MobileContainer = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`

export const StudentText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.primary};
  font-size: ${typography.h3};
  ${Media.lessThan(Media.small)} {
    height: 75px;
    font-size: ${typography.h4};
  }
`
