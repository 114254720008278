import styled from 'styled-components'
import { Column, Flex, H1, H2, LightSection, Row, Section } from '@renderbus/common/components'
import { Media, spacing, typography } from '@renderbus/common/theme'
import EducationDiamond from '../images/educate-disacount-plan/educate-diamond.svg'
export const OnlyForAnimation = styled.div`
  background: #ffffff;
  min-width: 172px;
  height: 34px;
  border-radius: 17px;
  line-height: 34px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #13cb75;
  font-weight: 500;
  position: absolute;
  top: 165px;
  ${Media.lessThan(Media.small)} {
    min-width: 172px;
    height: 35px;
    line-height: 35px;
    top: 180px;
    transform: scale(80%);
  }
`

export const DiamondIcon = styled.div`
  width: 26px;
  height: 20px;
  background: ${`url(${EducationDiamond})`} no-repeat;
  background-size: cover;
  margin: 0 5px 0 16px;
`
export const BannerContainer = styled(Flex)`
  position: relative;
  height: 600px;
  z-index: 0;
  ${Flex} {
    flex-direction: column;
  }
  ${H1} {
    letter-spacing: 7px;
  }
  ${H2} {
    letter-spacing: 3px;
  }
`

export const DesList = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 25px;
  color: white;
  margin: 10px auto;
`
export const EnterpriseRightTitle = styled.h3`
  margin: 45px 0 0;
  font-size: ${typography.h3};
  color: white;
  font-weight: normal;
  line-height: 1;
  ${Media.lessThan(Media.small)} {
    margin: 0;
    font-size: ${typography.h4};
  }
`
export const EnterpriseRightContext = styled.p`
  margin: 24px 0 0;
  font-size: ${typography.textSmall};
  color: #999;
  ${Media.lessThan(Media.small)} {
    margin: 15px 0 0;
  }
`

export const GocloudTitle = styled.h2`
  margin: 0 0 70px;
  line-height: 1;
  font-size: ${typography.title};
  text-align: center;
  color: white;
  ${Media.lessThan(Media.small)} {
    margin: 0 0 ${spacing.base};
    font-size: ${typography.h3};
  }
`

export const StudentSection = styled(Section)`
  ${Row} {
    align-items: stretch;
  }
  ${Flex} {
    position: relative;
  }
  ${EnterpriseRightTitle} {
    margin-top: 37px;
    ${Media.lessThan(Media.small)} {
      margin-top: 0;
    }
  }
  ${Column} {
    ${Media.lessThan(Media.small)} {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 0 ${spacing.large};
      transform: perspective(1);
      ${Flex} {
        transform: perspective(1) scale(0.5);
        width: 130px;
      }
    }
  }
`

export const EnterpriseSection = styled(LightSection)`
  ${Column} {
    ${Media.lessThan(Media.small)} {
      display: flex;
      justify-content: space-between;
      padding: 0 ${spacing.large};
      svg {
        transform: scale(0.5);
      }
    }
  }
`

export const DashedArrow = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  border-bottom: 2px dashed #bbb;
  ::after {
    position: absolute;
    top: -5px;
    right: -10px;
    content: '';
    width: 10px;
    height: 10px;
    border-top: 2px solid #bbb;
    border-right: 2px solid #bbb;
    transform: rotate(45deg);
  }
  ${Media.lessThan(Media.small)} {
    left: 50%;
    top: 150%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
`
export const EnterpriseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.lessThan(Media.small)} {
    width: 150px;
    align-items: flex-start;
    justify-content: center;
  }
`

export const EducationH2 = styled.p`
  margin: 0;
  color: white;
  font-size: ${typography.h2};
  ${Media.lessThan(Media.small)} {
    padding: 0 5vw;
    height: 2.5rem;
    letter-spacing: 4px !important;
    text-align: justify;
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 0;
    }
  }
`

export const FlexFixSafari = styled(Flex)`
  ${Media.lessThan(Media.small)} {
    transform: perspective(0);
  }
`
export const CompanyTitle = styled.p`
  margin: 0;
  color: white;
  font-size: ${typography.h1};
`
export const MoreModeTitle = styled.p`
  margin: 0;
  color: white;
  font-size: ${typography.h2};
`
